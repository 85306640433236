export default {
  blogerName: 'ISAICH',
  socialsList: [
    {
      name: 'kick',
      url: 'https://kick.com/isaich',
    },
    {
      name: 'telegram',
      url: 'https://t.me/DimonIsaich',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@DimonIsaich?si=jNkULHFXSIPvMOhg',
    },
    {
      name: 'tiktok',
      url: 'https://www.tiktok.com/@isaich_youtube?_t=8gB2QYTYcQU&_r=1',
    },
    {
      name: 'tiktok',
      url: 'https://www.tiktok.com/@isaich_youtubestream?_t=8gB2US1bSmA&_r=1',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c7742929f',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/ce141f3ed',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c6108bd7a',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cdecd574c',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/ce7f344bb',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/c351f9b58',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c471c0de4',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c62707f36',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-azhqmrpiks.com/c08c4ad2e',
      gameTitle: 'Doors Of Fresh (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>BANK</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'BANK',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
